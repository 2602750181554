import React from 'react'
import PropTypes from 'prop-types'
import './Button.scss'
import {Button} from 'reactstrap'

const ButtonCmp = ({
  className,
  onClick,
  title,
  type,
  disabled,
  color
}) => (
  <Button 
    type={type} 
    onClick={onClick} 
    className={className} 
    disabled={disabled}
    color={color}
    >
    {title}
  </Button>
)

ButtonCmp.propTypes = {
  size: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool
}

export default ButtonCmp
