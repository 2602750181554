import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row
} from 'reactstrap'
import scrollTo from 'gatsby-plugin-smoothscroll'
import './Header.scss'
import Button from '../../components/UI/Button/Button'
import LinkComponent from '../../components/Link/Link'


const Header = ({
  image,
  title,
  subtitle,
  actionTitle,
  btnClass = 'btn-1',
  section
}) => {
  let newTitle, titleSpan, titleLink, titleLastIndex
  if (title && title.length > 0 && title.includes('<span>')) {
    newTitle = title.split('<span>')
    titleSpan = title.substring(title.lastIndexOf("<span>") + 6, title.lastIndexOf("</span>"))
    titleLastIndex = newTitle[newTitle.length - 1].substring(newTitle[newTitle.length - 1].lastIndexOf("</span>") + 7, newTitle[newTitle.length - 1].lastIndexOf(""))
  } else if ( title && title.length > 0 && title.includes('<link>')) {
    newTitle = title.split('<link>')
    titleLink = title.substring(title.lastIndexOf("<link>") + 6, title.lastIndexOf("</link>"))
  }
  return (
    <section className='header' id='header'>
      <Row>
        <Col id='leftHalf'>
          <div className='header-text'>
            <h1>
              { newTitle && newTitle.length > 0 ? newTitle[0] : title } 
              { titleLink && titleLink.length > 0 && <LinkComponent to='/contact'>{titleLink}</LinkComponent> }
              { titleSpan && titleSpan.length > 0 && <span>{titleSpan}</span> }
              { titleLastIndex && titleLastIndex.length > 0 && titleLastIndex}
            </h1>
            <p>{subtitle}</p>
            <Button onClick={() => scrollTo(`#${section}`)} color='primary' className={`borderless ${btnClass} medium`} title={actionTitle} />
          </div>
        </Col>
        <Col className='header-text'  id='rightHalf'>
          <Col className='header-image' style={{ backgroundImage: `url(${image})`}}></Col>
        </Col>
      </Row>
    </section>
  )
}

Header.propTypes = {
  darkMode: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  image: PropTypes.any,
  actionTitle: PropTypes.any,
  btnClass: PropTypes.string
}

export default Header
