import i18n from "i18next";
import { initReactI18next } from 'react-i18next'

import translationEN from './locales/en/en.json';
import translationES from './locales/es/es.json';
import translationPT from './locales/pt/pt.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  pt: {
    translation: translationPT
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  })


export default i18n
